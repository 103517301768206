import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.div`
  @media ${query.desktop} {
    display: grid;
    grid-template-columns: 70% 30%;
  }
`;

export const ContentWrapper = styled.div`
  margin-bottom: 3rem;

  @media ${query.desktop} {
    grid-row: 1/3;
    margin-right: 4.4rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
  }
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme, $isSemiBold }) =>
    $isSemiBold ? theme.font.weight.semiBold : theme.font.weight.regular};
  margin: 2rem auto 0;
  line-height: 2.8rem;

  @media ${query.desktop} {
    margin: 0;
  }
`;

export const ParagraphBig = styled.p`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.paragraphBig};
  font-weight: ${({ theme }) => theme.font.weight.light};
  margin: 3rem auto;
  line-height: 3.6rem;

  @media ${query.desktop} {
    color: ${({ theme }) => theme.color.textPrimary};
    font-family: ${({ theme }) => theme.font.family.workSans};
    font-size: ${({ theme }) => theme.font.size.paragraph};
    line-height: 2.8rem;
    margin: 0;
  }
`;

export const ParagraphHighlight = styled.h3`
  display: none;

  @media ${query.desktop} {
    display: inline;
    color: ${({ theme }) => theme.color.textTertiary};
    font-family: ${({ theme }) => theme.font.family.archivo};
    font-size: ${({ theme }) => theme.font.size.headingSmall};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin: 0 0 2.6rem 0;
    line-height: 3.6rem;
  }
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.color.primaryOrange};
  text-decoration: none;
  font-style: italic;
`;

export const WaveIconBig = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    top: 1rem;
    right: -4rem;
  }
`;
