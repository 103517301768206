import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './FreeTimeSection.styles';
import SectionWrapper from '../Common/SectionWrapper';
import lightbulbImg from '../../assets/icons/lightbulb.svg';
import { theme } from '../../styles/theme';
import SectionHeaderText from '../Common/Texts/SectionHeaderText';

const FreeTimeSection = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper backgroundColor={theme.color.backgroundSecondary}>
      <SectionHeaderText>{t('about_freetime_title')}</SectionHeaderText>
      <S.Wrapper>
        <S.Paragraph>{t('about_freetime_paragraph_1')}</S.Paragraph>
        <S.ImageWrapper>
          <S.LightbulbImg src={lightbulbImg} alt="lightbulb" />
        </S.ImageWrapper>
        <S.Paragraph>{t('about_freetime_paragraph_2')}</S.Paragraph>
        <S.Paragraph $isSemiBold>— Maria Kmita</S.Paragraph>
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default FreeTimeSection;
