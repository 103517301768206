import React from 'react';
import { useTranslation } from 'react-i18next';

import SectionWrapper from '../Common/SectionWrapper';
import { theme } from '../../styles/theme';
import * as S from './HumorHistory.styles';
import PrimaryButtonWithLink from '../Common/Buttons/PrimaryButtonWithLink';
import routes from '../../static/constants/routes';
import waveBigIcon from '../../assets/icons/wave-big-orange.svg';
import SectionHeaderText from '../Common/Texts/SectionHeaderText';

const HumorHistory = () => {
  const { t } = useTranslation();

  return (
    <SectionWrapper backgroundColor={theme.color.backgroundPrimary}>
      <SectionHeaderText>{t('about_history_title')}</SectionHeaderText>
      <S.Wrapper>
        <S.ContentWrapper>
          <S.WaveIconBig src={waveBigIcon} alt="wave-big" />
          <S.Paragraph>{t('about_history_paragraph_1')}</S.Paragraph>
          <S.ParagraphBig>{t('about_history_paragraph_2')}</S.ParagraphBig>
          <S.Paragraph $isSemiBold>
            {t('about_history_paragraph_3')}
          </S.Paragraph>
          <S.Paragraph>
            {t('about_history_paragraph_4_pt1')}{' '}
            <span>
              <S.Link
                href="https://uwr.edu.pl/centra-badawcze/centrum-badan-kognitywnych-nad-jezykiem-i-komunikacja/projekty/?fbclid=IwAR398CmO6t5oRywWuABzyEkjfGVhoQY7JiZ42nprew9DEsaKIzt4lk3XxVw"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('about_history_paragraph_4_span')}
              </S.Link>
            </span>
            {t('about_history_paragraph_4_pt2')}
          </S.Paragraph>
        </S.ContentWrapper>

        <S.ParagraphHighlight>
          {t('about_history_paragraph_2')}
        </S.ParagraphHighlight>
        <PrimaryButtonWithLink
          routeTo={routes.publications}
          text={t('about_history_button')}
        />
      </S.Wrapper>
    </SectionWrapper>
  );
};

export default HumorHistory;
