import React from 'react';
import styled from 'styled-components';
import WaveSmallIcon from '../Icons/WaveSmallIcon';
import { query } from '../../../styles/mediaQuery';

const Header = styled.h2`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: 2.8rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 auto 1.6rem;
  position: relative;

  @media ${query.desktop} {
    font-size: 4.2rem;
  }
`;

const WaveImageWrapper = styled.div`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

interface SectionHeaderTextProps {
  children: React.ReactNode;
}

const SectionHeaderText = ({ children }: SectionHeaderTextProps) => {
  return (
    <Header>
      {children}
      <WaveImageWrapper>
        <WaveSmallIcon />
      </WaveImageWrapper>
    </Header>
  );
};

export default SectionHeaderText;
