import React from 'react';
import { theme } from '../../../styles/theme';

interface WaveSmallIconProps {
  color?: string;
}

const WaveSmallIcon = ({ color }: WaveSmallIconProps) => {
  return (
    <svg
      width="140"
      height="20"
      viewBox="0 0 140 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M95.6283 20C93.6976 20 91.8816 19.2218 90.5116 17.8038L79.5134 6.42045C78.9527 5.84007 78.0415 5.84007 77.4871 6.42045L66.7375 17.5466C64.0103 20.3759 59.2376 20.3759 56.5104 17.5466L46.0794 6.7568C45.5186 6.17642 44.6074 6.18301 44.0531 6.7568L33.6858 17.4872C30.8693 20.4023 26.2751 20.4089 23.4587 17.4872L12.9575 6.6117C12.5943 6.23577 12.1674 6.17643 11.9444 6.17643C11.7214 6.17643 11.2944 6.23577 10.9312 6.6117L4.9479 12.8046C3.81368 13.9786 1.97852 13.9786 0.850667 12.8046C-0.283556 11.6307 -0.283556 9.73125 0.850667 8.56389L6.83402 2.37098C9.56125 -0.458368 14.3339 -0.458368 17.0611 2.37098L27.5622 13.2465C28.123 13.8269 29.0342 13.8203 29.5885 13.2465L39.9559 2.51608C42.7723 -0.399011 47.3665 -0.405606 50.183 2.51608L60.614 13.3124C60.9772 13.6884 61.4041 13.7477 61.6271 13.7477C61.8502 13.7477 62.2771 13.6884 62.6403 13.3124L73.3899 2.18632C76.2063 -0.728772 80.8006 -0.728772 83.617 2.18632L94.6152 13.5697C94.9784 13.9456 95.4053 14.0049 95.6283 14.0049C95.8513 14.0049 96.2783 13.9456 96.6415 13.5697L107.589 2.23907C110.322 -0.590276 115.089 -0.583681 117.816 2.23907L128.4 13.1937C128.763 13.5697 129.19 13.629 129.413 13.629C129.636 13.629 130.063 13.5697 130.426 13.1937L135.052 8.4056C136.186 7.23165 138.021 7.23165 139.149 8.4056C140.284 9.57955 140.284 11.479 139.149 12.6463L134.523 17.4345C131.796 20.2638 127.023 20.2638 124.296 17.4345L113.712 6.4798C113.349 6.10387 112.922 6.04451 112.699 6.04451C112.476 6.04451 112.049 6.10387 111.686 6.4798L100.739 17.8104C99.3751 19.2218 97.5591 20 95.6283 20Z"
        fill={color ?? theme.color.primaryOrange}
      />
    </svg>
  );
};

export default WaveSmallIcon;
