import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.div`
  @media ${query.desktop} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem 4rem;
    margin-top: 3.6rem;
  }
`;

export const Header = styled.h1`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.headingSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 auto 1.6rem;
  position: relative;

  @media ${query.desktop} {
    margin: 0;
  }
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme, $isSemiBold }) =>
    $isSemiBold ? theme.font.weight.semiBold : theme.font.weight.regular};
  margin: 0 auto 2rem;
  line-height: 2.8rem;

  @media ${query.desktop} {
    margin: 0;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${query.desktop} {
    grid-row: 1/5;
  }
`;

export const LightbulbImg = styled.img`
  max-width: 55rem;
  width: 100%;
  margin: 0 auto 3rem;
`;
