import React, { useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './VideoSection.styles';
import PrimaryButtonWithLink from '../Common/Buttons/PrimaryButtonWithLink';
import routes from '../../static/constants/routes';
import dotsIcon from '../../assets/icons/dots-gray-icon.svg';
import playIcon from '../../assets/icons/play-icon.png';
import speakerIcon from '../../assets/icons/speaker-icon.svg';
import muteIcon from '../../assets/icons/mute-icon.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import { query } from '../../styles/mediaQuery';

const VIDEO_URL =
  'https://firebasestorage.googleapis.com/v0/b/optymisja-64d94.appspot.com/o/tvn-video-maria.mp4?alt=media';

const isIOS = () => {
  if (typeof document === `undefined`) return false;

  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

const VideoSection = () => {
  const { t } = useTranslation();
  const videoRef = useRef();
  const isDesktop = useMediaQuery(query.desktop);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  const playVideo = useCallback(() => {
    videoRef.current.play();
    setIsPlaying(true);
  }, []);

  const cancelVideo = useCallback(() => {
    videoRef.current.pause();
    setIsPlaying(false);
  }, []);

  const handleTogglePlay = (e) => {
    e.stopPropagation();

    if (videoRef.current.paused) {
      playVideo();
    } else {
      cancelVideo();
    }
  };

  const handleToggleMute = (e) => {
    e.stopPropagation();
    const videoElement = videoRef.current;

    setIsMuted((prev) => !prev);
    videoElement.muted = !videoElement.muted;
  };

  const handleClickWrapper = (e) => {
    handleTogglePlay(e);
  };

  return (
    <S.Wrapper onClick={handleClickWrapper}>
      <S.Overlay>
        <S.SpeakerIcon
          onClick={handleToggleMute}
          src={isMuted ? muteIcon : speakerIcon}
          alt="speaker"
        />
        <S.DotsIcon src={dotsIcon} alt="dots" />
        {!isPlaying && <S.PlayIcon src={playIcon} alt="play icon" />}
        <S.WatchMore>{`${t('about_see_also')}:`}</S.WatchMore>
        {isDesktop && (
          <PrimaryButtonWithLink
            text={t('about_video_btn')}
            routeTo={routes.publications}
          />
        )}
      </S.Overlay>
      <S.VideoWrapper>
        {isIOS() ? (
          <S.Video
            ref={videoRef}
            controls
            controlsList="nodownload nofullscreen"
            src={VIDEO_URL}
            playsinline
          />
        ) : (
          <S.Video
            ref={videoRef}
            controlsList="nodownload nofullscreen"
            autoPlay
            muted
            src={VIDEO_URL}
            playsinline
          />
        )}
      </S.VideoWrapper>
    </S.Wrapper>
  );
};

export default VideoSection;
