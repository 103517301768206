import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.section`
  position: relative;
`;

export const VideoWrapper = styled.div`
  padding: 56.25% 0 0 0;

  @media ${query.desktop} {
    padding: 0;
    height: 500px;
  }
`;

export const Video = styled.video`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.8);
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WatchMore = styled.h3`
  display: none;

  @media ${query.desktop} {
    display: block;
    color: ${({ theme }) => theme.color.backgroundPrimary};
    font-family: ${({ theme }) => theme.font.family.archivo};
    font-size: ${({ theme }) => theme.font.size.headingSmall};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    margin: 0 0 2rem;
  }
`;

export const DotsIcon = styled.img`
  display: none;

  @media ${query.desktop} {
    display: block;
    position: absolute;
    bottom: 4rem;
    left: -16rem;
  }
`;

export const PlayIcon = styled.img`
  @media ${query.desktop} {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: 45px;
    height: 45px;
    cursor: pointer;
  }
`;

export const SpeakerIcon = styled.img`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;

  @media ${query.desktop} {
    width: 45px;
    height: 45px;
  }
`;
