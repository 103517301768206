import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';

export const Wrapper = styled.div`
  height: 19rem;
  position: relative;

  @media ${query.desktop} {
    height: 28rem;
  }

  div {
    height: 100% !important;
    width: 100% !important;
  }

  img {
    height: 100% !important;

    @media ${query.desktop} {
      height: 140% !important;
    }
  }
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderText = styled.h1`
  color: ${({ theme }) => theme.color.backgroundPrimary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: 7.2rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  opacity: 40%;
  margin: 0;
  z-index: 2;

  @media ${query.tablet} {
    font-size: 9.6rem;
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
  }

  @media ${query.desktop} {
    font-size: ${({ theme }) => theme.font.size.pageTitle};
  }
`;
