import styled from 'styled-components';
import { query } from '../../styles/mediaQuery';
import { theme } from '../../styles/theme';

export const OuterWrapper = styled.div`
  position: relative;
`;

export const HeaderSpan = styled.span`
  color: ${theme.color.primaryOrange};
`;

export const HeaderDescription = styled.p`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0 auto 1.6rem;
  position: relative;
`;

export const HeaderDescriptionLi = styled.li`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: 1.8rem;
  font-weight: 400;
  margin: 0.8rem 0;
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${query.desktopLg} {
    flex-direction: row-reverse;
    gap: 2rem;
  }
`;

export const ImageWrapper = styled.div`
  padding: 0;
  max-width: 60rem;
  max-height: 60rem;
  margin: 2.4rem auto 0;

  @media ${query.desktop} {
    flex: 1 0 600px;
    margin-top: 0;
    align-self: center;
  }
`;

export const ContentWrapper = styled.div`
  position: relative;

  @media ${query.desktop} {
    padding-left: 4rem;
  }
`;

export const Header = styled.h1`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: ${({ theme }) => theme.font.size.headingSmall};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin: 0 auto 1.6rem;
  position: relative;
`;

export const Subheader = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};

  line-height: 2.8rem;

  @media ${query.desktop} {
  }
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 1.6rem auto 0;
  line-height: 2.8rem;

  @media ${query.desktop} {
    width: auto;
  }
`;

export const List = styled.ul`
  margin: 0;
`;

export const ListItem = styled.li`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  margin: 0;
  line-height: 2.8rem;
  list-style-type: disc;
  list-style-position: inside;

  @media ${query.desktop} {
  }
`;

export const ScroolTopBtn = styled.button`
  width: 4.6rem;
  height: 4.6rem;
  background-color: ${({ theme }) => theme.color.primaryOrange};
  border: none;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  bottom: 1rem;
  right: 0;

  @media ${query.desktop} {
    display: none;
  }
`;
