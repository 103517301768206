export const seoData = {
  home: {
    pl: {
      title: 'Optymisja - pozytywne warsztaty i szkolenia dla firm',
      description:
        'Pozytywne warsztaty i szkolenia dla firm. Zadbaj o swoich pracowników w innowacyjny sposób. Stres? Wypalenie zawodowe? Dezintegracja zespołu? Pomogę Ci wdrożyć strategiczny optymizm w Twoim miejscu pracy.',
    },
    en: {
      title: 'Optymisja - positive workshops and training for companies',
      description:
        'Positive workshops and trainings for companies. Take care of your employees in an innovative way. Stress? Work burnout? Team falling apart? I will help you implement a stategic optimism at your workplace.',
    },
  },
  offer: {
    pl: {
      title: 'Optymisja - oferta warsztatów i szkoleń dla pracowników',
      description:
        'Razem stwórzmy pozytywne miejsce pracy. Optymisja oferuje warsztaty bazujące na badaniach naukowych w dziedzinie humoru i optymizmu w miejscu pracy i nawiązujące do tradycji pozytywnej psychologii.',
    },
    en: {
      title: 'Optymisja - workshop offer for employees',
      description:
        'Optymisja offers workshops based on scientific research in the field of humour and optimism  in the workplace and draws upon the traditions of positive psychology.',
    },
  },
  blog: {
    pl: {
      title:
        'Optymisja - blog o humorze i pozytywnej psychologii w miejscu pracy',
      description:
        'Poznaj świat humoru i pozytywnej psychologii. Jak humor i optymizm może pomóc Twoim pracownikom i Twoijej firmie?',
    },
    en: {
      title:
        'Optymisja - blog on humour and positive psychology at a workplace',
      description:
        'Discover the world of humour and positive psychology. How can humour and optimism help your employees and your company?',
    },
  },
  about: {
    pl: {
      title:
        'Optymisja - o mnie. Dr Maria Kmita, wykształcenie, doświadczenie zawodowe, zainteresowania',
      description:
        'Skąd się znam na humorze? Co jest tak niesmowitego w humorze i optymizmie? Jakie mają znaczenie w naszym codziennym prywatnym i zawodowym życiu?',
    },
    en: {
      title:
        'Optymisja - about me. Dr Maria Kmita, education, work experience, interests',
      description:
        'How do I know what I know about humour? What is so fasciniting about humour and optimism? How important are they in our daily private and work and life? ',
    },
  },
  publications: {
    pl: {
      title:
        'Optymisja - publikacje na temat humoru w życiu zawodowym i prywatnym',
      description:
        'Dowiedz się więcej - poznaj podcast, książki, publikacje i wiele innych. Humoru i optymizmu można się nauczyć. Zobacz jakie są korzyści z bycia pozytywnym korpo sapiens',
    },
    en: {
      title:
        'Optymisja - publications on humor in professional and private life',
      description:
        'Learn more - explore podcasts, books, publications and more. Humour and optimism can be learned. Check out the benefits of being a positive corpo sapiens',
    },
  },
  poprawczak: {
    pl: {
      title: 'Optymisja - poprawczak nastroju',
      description:
        'Poprawczak nastroju to zbiór zabawnych obserwacji i refleksji nad różnymi problemami życia codziennego osobników dorosłych. Poprawia nastrój w dni robocze i święta. Pokazuje jak pozytywnie podejść do problemów.',
    },
    en: {
      title: 'Optymisja - poprawczak nastroju',
      description:
        'Poprawczak nastroju to zbiór zabawnych obserwacji i refleksji nad różnymi problemami życia codziennego osobników dorosłych. Poprawia nastrój w dni robocze i święta. Pokazuje jak pozytywnie podejść do problemów.',
    },
  },
  contact: {
    pl: {
      title:
        'Optymisja - kontakt. Zamów wyjątkowe warsztaty dla swoich pracowników',
      description:
        'Skontaktuj się z nami jeśli chcesz, aby Twoje miejsce pracy było bardziej pozytywne, Twoi pracownicy mniej zestresowani i bardziej zintegrowani.',
    },
    en: {
      title:
        'Optymisja - contact us and book unique workshops for your employees',
      description:
        'Contact us if you want your workplace to be more positive, your employees to be less stressed and your teams to be stronger.',
    },
  },
  privacy: {
    pl: {
      title: 'Optymisja - polityka pywatności',
      description: 'Polityka prywatności',
    },
    en: {
      title: 'Optymisja - privacy policy',
      description: 'Privacy policy',
    },
  },
  notFound: {
    pl: {
      title: 'Optymisja - nie znaleziono strony',
      description: 'Nie znaleziono strony',
    },
    en: {
      title: 'Optymisja - page not found',
      description: 'Page not found',
    },
  },
};
