import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import * as S from '../Common/Header.styles';

const HeaderAbout = () => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.HeaderWrapper>
        <S.HeaderText>{t('about_header')}</S.HeaderText>
      </S.HeaderWrapper>
      <StaticImage
        src="../../assets/images/maria-kmita.png"
        alt="maria-kmita"
        placeholder="blurred"
      />
    </S.Wrapper>
  );
};

export default HeaderAbout;
