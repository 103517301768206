import styled from 'styled-components';
import { query } from '../styles/mediaQuery';
import { theme } from '../styles/theme';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${theme.color.orangeLight};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2.8rem 1.6rem;

  @media ${query.desktop} {
    flex-direction: row;
    gap: 6.4rem;
  }
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media ${query.desktop} {
    align-items: flex-start;
    justify-content: center;
  }
`;

export const BookTitle = styled.h3`
  font-size: ${theme.font.size.headingSmall};
  font-family: ${theme.font.family.archivo};
  color: ${theme.color.textPrimary};
  margin: 0;
  text-align: center;
`;

export const BookSubtitle = styled.p`
  font-size: ${theme.font.size.paragraph};
  font-family: ${theme.font.family.workSans};
  color: ${theme.color.textPrimary};
  margin: 0;
  text-align: center;
`;
