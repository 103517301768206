import React from 'react';
import { useTranslation } from 'react-i18next';
import routes from '../static/constants/routes';

import * as S from './BookPromotionsBanner.Styles';
import PrimaryButtonWithLink from './Common/Buttons/PrimaryButtonWithLink';

const BookPromotionBanner = () => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.InnerWrapper>
        <S.BookTitle>Poprawczak nastroju</S.BookTitle>
        <S.BookSubtitle>{t('book_banner_text')}</S.BookSubtitle>
      </S.InnerWrapper>
      <PrimaryButtonWithLink
        text={t('book_banner_btn')}
        routeTo={routes.poprawczak}
      />
    </S.Wrapper>
  );
};

export default BookPromotionBanner;
